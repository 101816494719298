.CLogin {
    min-height: 400px;
}

div.card {
    background-color: transparent;
    min-width: 50%;
}

/*
div.card-header {
   background-color: #e0a2a2;
   color: black;
}
*/

.subscribe {
}

.forgot-password {
    padding-right: 10px;
}

.loginError {
    padding-top: 10px;
}

.googleButtonAppend {
    /* same a login button */
    display: inline-block !important;
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
    text-align: center;
    vertical-align: middle;
    margin-top: 10px;
    padding-bottom: 5px;
    padding-left: 2px;
    padding-right: 10px;
    font-family: inherit !important;
    font-weight: 400 !important;
    border-radius: 0.25rem !important;
    /* no right padding issue in french */
    width: 260px !important;
}