.chead {
    position: relative;
    /* font-family: 'Lobster';font-size: 22px; */
    font-family: 'MSO';
    font-size: 52px;

    display: flex;
    padding-top: 5px;
    padding-bottom: 10px;
}

.chleft {
    color: orange;
    float: left;
    margin-top: 100px;
    margin-left: 180px;
}

.chright1 {
    color: orange;
    float: right;
    position: absolute;
    right: 0;
    margin-top: 80px;
    margin-right: 200px;
}

.chright2 {
    color: orange;
    float: right;
    position: absolute;
    right: 0;
    margin-top: 160px;
    margin-right: 200px;
}


@media screen and (max-width: 1000px) {
    .chead {
        font-family: 'MSO';
        font-size: 22px;
        padding-top: 20px;
    }

    .chleft {
        color: orange;
        float: left;
        margin-top: 10px;
        margin-left: 15px;
    }

    .chright1 {
        display: none;
    }

    .chright2 {
        display: none;
    }
}

/* Image de fond test */
#conteneur {
    position: relative;
    border: 2px ridge grey;
    width: 100%;
    box-shadow: 10px 5px 5px grey;
    margin-bottom: 10px;
}

#conteneur img {
    height: 100%;
    width: 100%;
}

#masque {
    width: 100%;
    height: 50px;
    position: absolute;
    top: 0px;
    font-size: 64px;
}