.chGallery {
    height: 300px;
}

.chGalleryAlone {
    height: 300px;
}

.chGalleryVideo {
    max-height: 300px;
}

.fullscreen .chGalleryVideo {
    max-height: 100vh;
}


.image-gallery-content:not(.fullscreen) .image-gallery-slide:not(.fullscreen) .image-gallery-image:not(.fullscreen) {
    max-height: 300px;
}
