
.loadSpinner {
    padding-top: 50px;
    height: 90px;
    width: 40px;
    margin: 0 auto;
}

.loadText {
    width: 200px;
    margin: 0 auto;
    padding-bottom: 50px;
}