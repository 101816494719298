.cMenuEntry {
    /*
      border-bottom: 1px solid lightgrey;
      border-right: 2px solid grey;
      */
}

.noLink {
    cursor: default;
    background-color: transparent !important;
}

.chmiddleLogo {
    border: 0px solid red;
}