#cBreadcrumb {
    z-index: 100;
}

.noListing {
    min-height: 200px;
    padding-left: 50px;
    padding-top: 100px;
}

#listingsList {
    padding-left: 15px;
}