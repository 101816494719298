nav.cmenu {
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
}

.navbar-nav {
    margin-right: 4px;
}

.navbar-collapse {
    padding-bottom: 5px;
    border-bottom: 1px solid black;
    background-color: #f7f1f1;
}

.dropdown-menu {
    background-color: #f7f1f1 !important;
}

.dropdown-item {
    color: black !important;
    background-color: #f7f1f1 !important;
}

.dropdown-item:hover {
    font-weight: bold !important;
}

.navbar-light .navbar-nav .nav-link {
    color: black !important;
}

.nav-link.active {
    background-color: lightgrey;
}

.flag-img {
    width: 1.33333333em;
}

.flag-icon-fr {
    background-image: url(./images/fr.svg);
}

.flag-icon-gb {
    background-image: url(./images/gb.svg);
}

.flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em;
    cursor: pointer !important;
}

.cartBadge {
    background-color: #f7f1f1 !important;
}
