html, body {
    margin: 0px !important;
    padding: 0px !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.website-font-change {
    font-family: "Merienda", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.8em;
}

a {
    color: black !important;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

a.txt {
    color: #3F3835 !important;
    text-decoration: underline !important;
    font-style: italic !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
}

.cBox {
    border: 1px solid green;
}

/* https://stackoverflow.com/questions/90178/make-a-div-fill-the-height-of-the-remaining-screen-space */
#root {
    display: flex;
    flex-flow: column;
    height: 100%;
    background-color: #f7f1f1;
}

.CLeftMenu {
    float: left;
    min-width: 200px;
    border: 0px solid green;
}

/* https://usefulangle.com/post/69/css-fixed-sidebar-scroll-content-100-percent-height */
#CContainer {
    height: 100%;
}

#CSidebar {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    width: 18%;
    overflow: auto;
}

#CAdminSidebar {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    overflow: auto;
}

#CContent {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    width: 82%;
    border: 0px solid green;
}

#CContentNormal {
    display: inline-block;
    vertical-align: top;
    width: 100%;
    border: 0px solid green;
}

#CContentFull {
    display: inline-block;
    vertical-align: top;
    height: 100%;
    width: 100%;
    overflow: auto;
    border: 0px solid green;
}

@media (min-width: 1200px) {
    #chContainer {
        max-width: 1300px !important;
    }
}

#CMainContent {
    /*
    overflow: auto;
    padding: 20px;
    min-height: 70vh;
    font-family: cursive;
    */
    color: black;
}

#CMainContent .table {
    color: black;
}

#CMainContent pre {
    color: black;
}

#cBreadcrumb .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0px;
    margin-bottom: 0.5rem;
    background-color: transparent;
}

.breadcrumb-item {
    display: flex;
    padding-left: 25px;
    padding-bottom: 5px;
    padding-top: 5px;
}

.breadcrumb-item.active {
    color: black;
    font-weight: bold;
}

/* tête de tableau */
.table thead th {
    background-color: lightgrey !important;
}

/* fix local style diff from _table.scss:34 */
.table tr td {
    background-color: transparent !important;
}

.alignCenter {
    text-align: center;
}

.alignRight {
    text-align: right;
}

.noWrap {
    white-space: nowrap;
}

.card {
    text-align: left;
}

.onRight {
    position: relative;
    float: right;
}

.oneRaw {
    height: 30px;
}

h1 {
    font-size: 1.33em !important;
    position: relative;
    padding: 15px;
    background: #e5e5e5;
    background: linear-gradient(#f5f5f5, #e5e5e5);
    box-shadow: 0 -1px 0 rgba(255, 255, 255, .8) inset;
    text-shadow: 0 1px 0 #fff;
}

h2 {
    font-size: 1.13em !important;
    position: relative;
    border-color: #eee;
    border-style: solid;
    border-width: 5px 5px 5px 0;
    background: lightgrey;
    margin: 0 0 15px 0px;
    padding: 5px 15px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, .3);
}

h3 {
    font-size: .93em !important;
    position: relative;
    border-color: #eee;
    border-style: solid;
    border-width: 5px 5px 5px 0;
    background: lightgrey;
    margin: 0 0 15px 0px;
    padding: 5px 15px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, .3);
}

p {
    margin: 0 0 15px 10px;
    padding-bottom: 10px;
}

.col {
    padding-right: 0px;
    padding-left: 0px;
}

.largeScreen {
    display: none !important;
}

.smallScreen {
    display: block !important;
}

@media only screen and (min-width: 1100px) {
    .largeScreen {
        display: block !important;
    }

    .smallScreen {
        display: none !important;
    }
}

.eyeFloatRight {
    margin-top: -30px;
    padding-right: 20px;
    float: right;
}

.appSideBar {
    display: none !important;
}

.react-tooltip {
    display: none !important;
}

@media only screen and (min-width: 1100px) {
    .appSideBar {
        display: inline-block !important;
    }

    .react-tooltip {
        display: inline-block !important;
    }
}

@font-face {
    font-family: 'Lobster';
    src: local('Lobster'), url(./fonts/Lobster-Regular.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'MSO';
    src: local('MSO'), url(./fonts/magic-school.one.ttf) format('truetype');
    font-display: swap;
}

/** tabs **/
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: grey !important;
    color: white !important;
    border-bottom: 2px solid transparent !important;
    border-right: 2px solid black !important;
    border-left: 2px solid black !important;
    border-top: 2px solid black !important;
}

.nav-fill .nav-item, .nav-fill > .nav-link {
    border-bottom: 2px solid grey !important;
}

.tab-content {
    padding-top: 5px;
}

.nav {
    padding-top: 10px;
}

.clearBoth {
    clear: both;
}

.h20 {
    height: 20px;
}

.floatLeft {
    float: left;
}

.pad-r20 {
    padding-right: 20px;
}

.floatRight {
    float: right;
}

.zup {
    z-index: 10;
}

.zoff {
    z-index: 1;
}

.endOfList {
    float: left;
    height: 30px;
    display: block;
    border: 0px solid green;
    opacity: 0;
}

.modalParent {
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.modalChild {
}

.chBadge {
    color: black !important;
}

.ml2 {
    margin-left: 2px;
}

/* css fix since react 18 */
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px;
    padding-right: 10px;
    padding-left: 10px;
}

.form-group {
    margin-bottom: 1rem;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
}

.btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-info.focus, .btn-info:focus, .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
}

/*
.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  max-width: 90% !important;
}
*/


.form-control {
    width: 100% !important;
}

.alert-info {
    --bs-alert-color: #055160;
    --bs-alert-bg: lightgrey;
    --bs-alert-border-color: grey;
}

.select-all-text {
    user-select: all;
    -moz-user-select: all;
    -webkit-user-select: all;
}

/**
 * admin only: action box, extra info
 */
.adminOnlyFeature {
    margin-top: 15px;
    background-color: #F5DBDB;
    padding: 10px;
    border: 1px dashed grey;
}