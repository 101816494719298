.searchBar {
    border: 0px solid green;
    margin-right: auto !important;
    width: 100%;
}

@media only screen and (min-width: 1100px) {
    .searchBar {
        border: 0px solid green;
        margin-left: 20px;
        margin-right: auto !important;
        width: 40%;
    }
}

.cSearch {
    width: 100%;
    position: relative;
    display: flex;
}

.cSearchTerm:focus {
    color: black;
}

.cSearchTerm {
    width: 100%;
    border: 2px solid lightgrey;
    border-right: none;
    padding: 5px;
    height: 33px;
    border-radius: 15px 0 0 15px;
    outline: none;
    color: #9DBFAF;
}

.cSearchButton {
    width: 40px;
    height: 33px;
    border: 1px solid grey;
    background: grey;
    text-align: center;
    color: #fff;
    border-radius: 0 15px 15px 0;
    cursor: pointer;
    font-size: 20px;
}

/*Resize the wrap to see the search bar change!*/
.cWrap {
    width: 30%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}