
.chSharedButton {
    padding-right: 5px !important;
}

.clistingShareButtons {
    font-style: italic;
    line-height: 2em;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 50px;
    padding-bottom: 50px;
}
