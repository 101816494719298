.entryLeft {
    float: left;
}

.entryRight {
    float: right;
}

.widthImageCell {
    width: 250px;
    margin: 2px;
    padding: 1px;
    vertical-align: top;
    cursor: pointer;
}

.widthImage {
    width: 250px;
    border: 1px solid grey;
    margin: 2px;
    padding: 1px;
}

.widthTitle {
    width: 50%;
    padding: 5px;
}

.widthPrice {
    width: 200px;
    padding: 5px;
}

.productTitle {
    font-size: 1.0rem;
}

.cartproductPriceLabel {
    width: 150px;
}

.btn-delete {
    box-shadow: none;
    border-color: transparent;
    background-color: transparent;
}

.btn-delete:hover {
    text-decoration: underline;
}

.table td, .table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 0 !important;
    border-bottom-width: 0px !important;
}