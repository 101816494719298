.footer {
    padding: 5px;
    bottom: 0;
    left: 0;
    width: 100%;
}

.footerIcons {
    position: relative;
    display: block;
    text-align: center;
    border: 0px solid red;
}

.footerIcon {
    padding: 5px;
    padding-right: 10px;
}

.clear {
    clear: both;
}

.footerSep {
    float: right;
    padding: 5px;
}

.footerSections {
    border: 0px solid blue;
}

.footerSection {
    float: right;
    padding: 5px;
}