.clistingPriceLabel {
    float: left;
    color: black !important;
    font-size: 1.0rem !important;
    font-weight: bold !important;
    vertical-align: middle;
    height: 20px;
    line-height: 40px; /* vertical align middle */
}

.detailsTips {
    border: 0px solid black;
    padding-top: 15px;
    padding-left: 15px;
}

.btn-cart {
    margin-top: 15px;
    margin-left: 15px;
}

.card-footer {
    background-color: transparent !important;
    border: 0px;
}

/*
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0 !important;
  margin-left: 0 !important;
}
*/

.customChoice {
    padding-top: 10px;
}

.toggleAdmin {
    font-size: 12px;
    text-align: right;
    text-decoration: underline overline #e83e8c;
    cursor: pointer;
}