.cproductParent {
}

.cproductLoading {
    display: 'none';
}

.card.cproduct {
    display: inline-block !important;
    width: 17rem !important;
    cursor: pointer !important;
    position: relative;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: transparent !important;
    background-clip: border-box;
    border-radius: .25rem;
    border: 0px solid green;
    margin: 5px;
    padding-bottom: 20px;
}

.cproductImage {
    object-fit: cover;
    width: 100%;
    max-height: 300px;
}

.cproductTitleText {
    display: inline-block !important;
    float: left;
    width: 78%;
    text-align: left;
    min-height: 60px;
    vertical-align: middle;
    padding: 8px;
}

.cproductPriceText {
    display: inline-block !important;
    float: right;
    line-height: 55px;
    color: black;
    white-space: nowrap;
}

.cproductOutTitleText {
    display: inline-block !important;
    float: left;
    width: 72%;
    text-align: left;
    min-height: 60px;
    vertical-align: middle;
    padding: 8px;
    color: grey;
}

.cproductOutText {
    display: inline-block !important;
    float: right;
    width: 28%;
    line-height: 55px;
    vertical-align: middle;
    color: black;
    padding: 2px;
    font-weight: bold;
    color: grey;
    white-space: nowrap;
}

p {
    margin-bottom: 0 !important;
}

.listTooltip {
    z-index: 1000;
}