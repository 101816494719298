.clisting {
}

.chGallery {
}

@media only screen and (min-width: 1100px) {
    .chGallery {
        padding-left: 10px;
        padding-right: 10px;
    }

    .chGalleryAlone {
        padding-left: 10px;
        padding-right: 100px;
    }

}


.card.clistingDetails {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border-radius: .25rem;
    padding: 15px;
    border: 0px solid red;
}

.clistingTitle {
    color: black;
    font-weight: 500;
    font-size: 1.0rem;
    line-height: 20px;
    padding: 5px;
}

.clistingPrice {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

.clistingVariationBox {
    padding-top: 5px;
    margin: 5px;
}

.clistingPriceBox {
    padding-top: 5px;
    margin: 5px;
}

.clistingButtonBox {
    float: right;
    padding-top: 5px;
    margin: 5px;
}

.sectionLink {
    color: #e0a2a2;
}

.sectionLink:hover {
    color: #e0a2a2;
}

.card.clistingDescription {
    display: flex;
    width: 100%;
    margin-top: 10px;
    padding: 15px;
    font-size: 1.0rem;
    border: 0px;
}

.hidden {
    display: none;
}

.smallWarn {
    font-weight: bold;
    color: red;
    padding-top: .4rem !important;
    margin-left: .4rem !important;
    line-height: 20px;
}