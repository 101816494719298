.switchState {
    margin-left: 10px;
    margin-bottom: 10px;
    font-weight: bold;
    float: right;
    width: 70px;
}

.modal {
    width: 80% !important;
}