.profileValue {
    color: black !important;
}

.required:after {
    content: " *";
    color: red;
}

.requiredLegend:before {
    content: " *";
    color: red;
}

.btnPanel {
    float: right;
}

.btn-padding {
    margin-right: 10px;
}