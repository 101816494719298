.chome {
}

.homeMsg {
    border-radius: 5px 0 0 5px;
    padding: 10px;
    color: red;
    font-size: 1.53em !important;
    font-weight: bold;
    text-align: center;
}